<template>
  <DashboardTemplate
    v-if="sections && loaded"
    :sections="sections"
    :grid-area-component="gridAreaComponentA()"
    :grid-rows="4"
    @selectGraph="selectGraph"
  >
    <template #navbarItems>
      <DashboardNavbarRouteItem
        root
        underlined
        :label="$t('dashboard.analytics.title')"
        @click="toAnalytics"
      />
      <DashboardNavbarRouteItem
        v-if="selectedSectionLabel !== null"
        underlined
        :label="selectedSectionLabel"
        @click="resetSelectedGraph"
      />
      <DashboardNavbarRouteItem
        v-if="!mobile && selectedGraphLabel !== null"
        :clickable="false"
        :label="selectedGraphLabel"
      />
    </template>
  </DashboardTemplate>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import DashboardTemplate from '@/components/dashboard/Template.vue';
import DashboardNavbarRouteItem from '@/components/dashboard/utils/NavbarRouteItem.vue';
import CONFIG from '@/config';
import { deepCopy } from '@/utils/objects';

export default {
  name: 'AnalyticsView',
  components: { DashboardTemplate, DashboardNavbarRouteItem },
  metaInfo() {
    return {
      title: `${this.$tc('dashboard.breadcrumbs.app_name')} | ${this.$tc('dashboard.breadcrumbs.analytics')}`,
      meta: [{ name: 'robots', content: 'noindex, nofollow' }],
    };
  },
  data() {
    return {
      loaded: false,
      analyticsSectionsCache: null,
      selectedSectionId: null,
      analyticsGraphsCache: null,
      selectedGraphId: null,
      selectedGraphLabel: null,
      analyticsReportsCache: null,
      selectedReportId: null,
      saeResultsReportCode22: 'sae_results_2022',
      saeResultsReportCode21: 'sae_results_2021',
      saeCompetitorsReportCode22: 'sae_competitors_2022',
      saeCompetitorsReportCode21: 'sae_competitors_2021',
      saeResultsReportSection22: {
        name: 'sae-results-report-22',
        showTitle: false,
        title: this.$t('dashboard.widget-title.vacancies-widget'),
        paddingSection: '0px',
        mobileHeight: '30%',
        gridArea: 'sae-results-report-22',
      },
      saeResultsReportSection21: {
        name: 'sae-results-report-21',
        showTitle: false,
        title: this.$t('dashboard.widget-title.vacancies-widget'),
        paddingSection: '0px',
        mobileHeight: '30%',
        gridArea: 'sae-results-report-21',
      },
      saeCompetitorsReportSection22: {
        name: 'sae-competitors-report-22',
        showTitle: false,
        title: this.$t('dashboard.widget-title.vacancies-widget'),
        paddingSection: '0px',
        mobileHeight: '30%',
        gridArea: 'sae-competitors-report-22',
      },
      saeCompetitorsReportSection21: {
        name: 'sae-competitors-report-21',
        showTitle: false,
        title: this.$t('dashboard.widget-title.vacancies-widget'),
        paddingSection: '0px',
        mobileHeight: '30%',
        gridArea: 'sae-competitors-report-21',
      },
    };
  },
  computed: {
    ...mapGetters({
      currentRole: 'roles/currentRole',
      campusDetail: 'roles/currentHeadMasterMainSchool',
      reportsWidgetsData: 'widgets/reportsWidgetsData',
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    hasSelectedGraph() {
      return !(this.selectedGraphId === null || this.selectedGraphId === undefined);
    },
    hasSelectedSectionId() {
      return !(this.selectedSectionId === null || this.selectedSectionId === undefined);
    },
    analyticsGraphs() {
      if (this.analyticsGraphsCache === null) {
        this.fetchGraphs();
        return [];
      }
      return this.analyticsGraphsCache.map((graph) => ({
        ...graph,
        key: `${this.campusDetail.campus_code}-${graph.sectionProps.id}`,
      }));
    },
    analyticsSections() {
      if (this.analyticsSectionsCache === null) {
        this.fetchSections();
        return [];
      }
      return this.analyticsSectionsCache;
    },
    selectedSectionLabel() {
      if (this.selectedSectionId && this.analyticsSections.length) {
        const subsections = this.analyticsSections[0].sectionProps.sections;
        const selectedSection = subsections.find(
          (section) => section.id === +this.selectedSectionId,
        );
        return selectedSection.name;
      }
      return null;
    },
    sections() {
      if (this.hasSelectedSectionId && this.hasSelectedGraph) {
        // If graph and section are selected
        // We specify the details for the selected graph, and hide the rest

        return this.analyticsGraphs.map((graph) => (graph.sectionProps.id === this.selectedGraphId
          ? this.parseSelectedGraph(graph)
          : this.parseHiddenGraph(graph)));
      }
      if (this.hasSelectedSectionId) {
        // If only section is selected we return and show all graphs
        return this.analyticsGraphs;
      }
      // If no section is selected we return and show all sections
      return this.analyticsSections;
      // Missing logic for reports - see how that will be
    },
  },
  watch: {
    $route: {
      handler() {
        this.setParams();
        this.resetCache();
      },
      deep: true,
    },
    campusDetail: {
      handler() {
        // This feature is only available in Chile for now
        if (CONFIG.tenant === 'chile') {
          this.launchReports();
        }
      },
      deep: true,
    },
    reportsWidgetsData: {
      handler() {
        this.fetchSections();
      },
      deep: true,
    },
  },
  mounted() {
    this.setParams();
    this.launchReports();
  },
  methods: {
    ...mapActions({
      getSections: 'roles/getAnalyticsSections',
      getSectionGraphs: 'roles/getAnalyticsSectionGraphs',
      getReports: 'widgets/getReports',
    }),
    setParams() {
      const { report, section } = this.$route.params;
      this.selectedSectionId = section;
      this.selectedReportId = report;
    },
    resetCache() {
      this.analyticsSectionsCache = null;
      this.analyticsGraphsCache = null;
      this.analyticsReportsCache = null;
    },
    toAnalytics() {
      this.$router.push({ name: 'Analytics' }).catch();
    },
    resetSelectedGraph() {
      this.selectedGraphId = null;
      this.selectedGraphLabel = null;
    },
    parseFetchedGraph({ graph_id: graphId }) {
      const graphSection = {
        // name: 'graph',
        name: 'analytics-graph',
        title: null,
        showTitle: false,
        show: true,
        sectionProps: {
          id: graphId,
          height: '90%',
        },
      };
      return graphSection;
    },
    parseSelectedGraph(graph) {
      const copy = deepCopy(graph);
      copy.row = '1 / 3';
      copy.col = '1 / 4';
      copy.mobileHeight = '100%';
      copy.sectionProps = {
        ...copy.sectionProps,
        detailed: true,
      };
      return copy;
    },
    parseHiddenGraph(graph) {
      return { ...graph, show: false };
    },
    fetchGraphs() {
      const params = {
        roleId: this.currentRole.roleInfo.id,
        sectionId: this.selectedSectionId,
      };
      this.getSectionGraphs(params).then((response) => {
        this.analyticsGraphsCache = response.data.map(this.parseFetchedGraph);
        this.loaded = true;
      });
    },
    fetchSections() {
      const params = {
        roleId: this.currentRole.roleInfo.id,
      };
      this.getSections(params).then(() => {
        this.analyticsSectionsCache = [
          // add @params response when uncommented the section below
          // {
          //   name: '',
          //   title: 'Visualización de datos',
          //   mobileHeight: '100%',
          //   permissions: ['headmaster', 'student', 'parent'],
          //   showTitle: false,
          //   sectionProps: {
          //     isSection: true,
          //     sections: response.data,
          //   },
          //   gridArea: '',
          // },
          {
            name: 'vacancies-widget',
            showTitle: false,
            title: this.$t('dashboard.widget-title.vacancies-widget'),
            paddingSection: '20px',
            mobileHeight: '100%',
            gridArea: 'vacancies-widget',
          },
        ];
        this.analyticsSectionsCache = this.analyticsSectionsCache.concat(this.reportsSectionsToDisplay());
        this.loaded = true;
      });
    },
    selectGraph(graphId, graphLabel) {
      if (this.selectedSectionId) {
        if (this.selectedGraphId === null || this.selectedGraphId === undefined) {
          this.selectedGraphId = graphId;
          this.selectedGraphLabel = graphLabel;
        } else {
          this.selectedGraphId = null;
          this.selectedGraphLabel = null;
        }
      }
    },
    gridAreaComponentA() {
      const classes = [
        this.reportsWidgetsData[this.saeResultsReportCode21] ? 'sae-results-report-21' : '.',
        this.reportsWidgetsData[this.saeResultsReportCode22] ? 'sae-results-report-22' : '.',
        this.reportsWidgetsData[this.saeCompetitorsReportCode21] ? 'sae-competitors-report-21' : '.',
        this.reportsWidgetsData[this.saeCompetitorsReportCode22] ? 'sae-competitors-report-22' : '.',
      ];

      return `"vacancies-widget vacancies-widget ${classes[0]} ${classes[2]}" "vacancies-widget vacancies-widget ${classes[1]} ${classes[3]}" "vacancies-widget vacancies-widget . ." "vacancies-widget vacancies-widget . ."`;
    },
    reportsSectionsToDisplay() {
      const sections = [];

      if (this.reportsWidgetsData[this.saeResultsReportCode22]) {
        sections.push(this.saeResultsReportSection22);
      }
      if (this.reportsWidgetsData[this.saeResultsReportCode21]) {
        sections.push(this.saeResultsReportSection21);
      }
      if (this.reportsWidgetsData[this.saeCompetitorsReportCode22]) {
        sections.push(this.saeCompetitorsReportSection22);
      }
      if (this.reportsWidgetsData[this.saeCompetitorsReportCode21]) {
        sections.push(this.saeCompetitorsReportSection21);
      }
      return sections;
    },
    launchReports() {
      this.getReports({ campusCode: this.campusDetail.campus_code, reportCode: this.saeResultsReportCode22 });
      this.getReports({ campusCode: this.campusDetail.campus_code, reportCode: this.saeResultsReportCode21 });
      this.getReports({ campusCode: this.campusDetail.campus_code, reportCode: this.saeCompetitorsReportCode22 });
      this.getReports({ campusCode: this.campusDetail.campus_code, reportCode: this.saeCompetitorsReportCode21 });
    },
  },
};
</script>
