<template>
  <div class="d-flex flex-row" @click="$emit('click')" @keydown="$emit('click')">
    <v-icon v-if="!root" small class="dashboard-navbar__separator">
      mdi-chevron-left
    </v-icon>
    <div
      class="dashboard-navbar__route"
      :class="{
        'dashboard-navbar__clickable': clickable,
        'dashboard-navbar__underlined': underlined,
      }"
    >
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardNavbarRouteItem',
  props: {
    label: {
      required: true,
      type: String,
    },
    root: {
      required: false,
      type: Boolean,
      default: false,
    },
    clickable: {
      required: false,
      type: Boolean,
      default: true,
    },
    underlined: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
};
</script>
